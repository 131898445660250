/* purgecss start ignore */

/* 系统主题颜色 */

/* 系统主题颜色 */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.ant-result {
  padding: 48px 32px;
}

.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}

.ant-result-error .ant-result-icon > .anticon {
  color: #ff4d4f;
}

.ant-result-info .ant-result-icon > .anticon {
  color: #1890ff;
}

.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}

.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}

.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}

.ant-result-icon > .anticon {
  font-size: 72px;
}

.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}

.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

.ant-result-extra {
  margin: 24px 0 0 0;
  text-align: center;
}

.ant-result-extra > * {
  margin-right: 8px;
}

.ant-result-extra > *:last-child {
  margin-right: 0;
}

.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}

.ant-result-rtl {
  direction: rtl;
}

.ant-result-rtl .ant-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}

.ant-result-rtl .ant-result-extra > *:last-child {
  margin-left: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Header_header__175Or{
  height:60px;
  padding:0 30px;
  line-height:60px;
  -webkit-backdrop-filter:blur(10px);
          backdrop-filter:blur(10px);
  border-bottom:1px solid rgba(255,255,255,.7);
  background:rgba(255,255,255,.7)
}

.Header_header__175Or .Header_nav__1mDv7{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:end;
      -ms-flex-pack:end;
          justify-content:flex-end
}

.Header_header__175Or .Header_nav__1mDv7 .active{
  border-bottom:2px solid #f73859
}

.Header_header__175Or .Header_nav__1mDv7 .ant-col{
  line-height:60px;
  text-align:center;
  cursor:pointer
}

.Header_header__175Or .Header_nav__1mDv7 .ant-col a{
  color:#333;
  display:block
}

.Header_header__175Or .Header_nav__1mDv7 .ant-menu-horizontal{
  border-bottom:0
}

.Header_header__175Or .logo img{
  height:60px;
  padding:10px 0
}

.Header_header__175Or .ant-menu{
  background:transparent
}

@media(min-width: 768px)and (max-width: 1299px){
  .Header_header__175Or{
    padding:0 10px
  }
}

@media(min-width: 480px)and (max-width: 767px){
  .Header_header__175Or{
    padding:0 10px;
    height:46px;
    line-height:46px
  }
}

@media(max-width: 479px){
  .Header_header__175Or{
    padding:0 10px;
    height:46px;
    line-height:46px
  }

  .Header_header__175Or .Header_nav__1mDv7 .ant-col{
    line-height:46px
  }

  .Header_header__175Or .logo img{
    height:35px;
    padding:4px 0
  }
}

.Header_sign__3pmt3{
  padding:15px 25px
}

.Header_sign__3pmt3 .ant-modal-body{
  padding-top:50px
}

.Header_sign__3pmt3 #login_container{
  text-align:center;
  height:280px
}

.Header_sign__3pmt3 .ant-tabs-nav-scroll{
  text-align:center
}

.Header_sign__3pmt3 .wx{
  text-align:center
}

.Header_sign__3pmt3 .wx p{
  line-height:26px;
  padding-top:2px;
  text-align:center
}

.Header_sign__3pmt3 h4{
  text-align:center;
  font-weight:normal;
  padding-bottom:15px
}

.Header_sign__3pmt3 .party{
  text-align:center;
  margin-top:15px
}

.Header_sign__3pmt3 .party p{
  text-align:center;
  padding-top:6px
}

.Header_sign__3pmt3 .party p span{
  letter-spacing:3px
}

.Header_sign__3pmt3 .party a{
  display:block;
  padding-top:10px;
  text-align:center;
  color:#666
}

.Header_sign__3pmt3 .party img{
  height:42px
}

/* purgecss end ignore */
/* purgecss start ignore */

.Footer_footer__Bcqmv{
  background:#fff;
  margin-top:60px
}

.Footer_footer__Bcqmv .content{
  margin:0 30px
}

.Footer_footer__Bcqmv .text{
  line-height:26px
}

.Footer_footer__Bcqmv .text .ant-col:nth-child(1){
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center
}

.Footer_footer__Bcqmv .text .ant-col:nth-child(2){
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:end;
      -ms-flex-pack:end;
          justify-content:flex-end
}

.Footer_footer__Bcqmv .text img{
  height:130px
}

.Footer_footer__Bcqmv .text a{
  color:#444;
  padding:10px 0 10px 40px
}

.Footer_footer__Bcqmv .text a p{
  margin-bottom:0;
  text-align:center;
  padding:8px 0
}

.Footer_footer__Bcqmv .copyright{
  text-align:center;
  line-height:40px;
  border-top:1px solid rgba(188,198,208,.4);
  font-size:12px
}

.Footer_footer__Bcqmv .link{
  padding-top:10px
}

.Footer_footer__Bcqmv .link a{
  color:#999;
  padding:10px
}

.Footer_footer__Bcqmv .link p{
  line-height:30px
}

.Footer_footer__Bcqmv .cooperation{
  padding-bottom:20px
}

.Footer_footer__Bcqmv .cooperation h4{
  padding-bottom:10px;
  font-weight:normal
}

.Footer_footer__Bcqmv .cooperation ul{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap
}

.Footer_footer__Bcqmv .cooperation ul li{
  -webkit-box-flex:1;
      -ms-flex:1 1 auto;
          flex:1 1 auto
}

.Footer_footer__Bcqmv .cooperation ul li a{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  padding:15px 0
}

.Footer_footer__Bcqmv .cooperation ul li a img{
  height:25px;
  width:auto;
  -webkit-filter:grayscale(100%);
          filter:grayscale(100%);
  -webkit-filter:gray;
          filter:gray
}

.Footer_footer__Bcqmv .cooperation ul li a:hover img{
  -webkit-filter:grayscale(0%);
          filter:grayscale(0%)
}

@media(min-width: 768px)and (max-width: 1299px){
  .Footer_footer__Bcqmv .content{
    margin:0 30px;
    padding-top:20px
  }
}

@media(min-width: 480px)and (max-width: 767px){
  .Footer_footer__Bcqmv .content{
    margin:0 30px;
    padding-top:20px
  }
}

@media(max-width: 479px){
  .Footer_footer__Bcqmv .content{
    margin:0 30px;
    padding-top:20px
  }
}

/* purgecss end ignore */
